import { Component, Vue } from 'vue-property-decorator';

import { BASE_API_URL } from '@/utils/Constants';

export enum BannerGroupsMixinTags {
  Index = 'BannerGroupIndex',
  Show = 'BannerGroupShow',
  Create = 'BannerGroupCreate',
  Update = 'BannerGroupUpdate',
  Destroy = 'BannerGroupDestroy'
}

const BASE_BANNER_GROUPS_URL = BASE_API_URL + "banner_groups";

@Component
export default class BannerGroupsMixin extends Vue {
  getBannerGroups(data: any, tag: string) {
    return this.$httpGetQuery(BASE_BANNER_GROUPS_URL, tag, data);
  }

  getBannerGroup(id: string, tag: string) {
    return this.$httpGet(BASE_BANNER_GROUPS_URL + "/" + id, tag);
  }

  createBannerGroup(data: any, tag: string) {
    return this.$httpPost(BASE_BANNER_GROUPS_URL, tag, data);
  }

  updateBannerGroup(id: number | string, data: any, tag: string) {
    return this.$httpPut(BASE_BANNER_GROUPS_URL+"/"+id, tag, data);
  }

  destroyBannerGroup(id: number | string, tag: string) {
    return this.$httpDelete(BASE_BANNER_GROUPS_URL+"/"+id, tag);
  }
}
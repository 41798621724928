













import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";

@Component({ components: { VSelect } })
export default class BannerGroupPageCodeSelect extends Vue {
  @Prop()
  selected!: string | null;

  @Prop({ default: 'Codice Pagina' })
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  options: VSelectOption[] = [
    { id: 'store_page', text: 'Store' },
    { id: 'checkout', text: 'Checkout' },
    { id: 'account', text: 'Account' },
  ];

  private value: string | null = this.selected || null;

  @Watch('selected')
  onSelectedChanged(selected: string) {
    this.value = selected;
    this.onItemsReady();
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected.toString())?.id;
    }
  }
}
